module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-50},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#78909c","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gui Hebling · Front-End Web Developer · Oslo, Norway","short_name":"Gui Hebling Dev","lang":"en","start_url":"/","display":"standalone","icon":"src/images/favicon-gh.png","icons":[{"src":"src/images/favicon-gh.png","sizes":"64x64","type":"image/png"},{"src":"src/images/favicon-gh-512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"background_color":"#37474f","theme_color":"#78909c","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"456ecb6a45dcf1d1f72d5f83ec5790b8"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
